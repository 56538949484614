export const AccessibilityConstants = {
  AMEN_ADA_COMMON: 'AMEN_ADA_COMMON',
  AMEN_ADA_ROOM: 'AMEN_ADA_ROOM',
  AMEN_ADA_BATH: 'AMEN_ADA_BATH',
};

export const AccessibilityData = [
  {
    id: AccessibilityConstants.AMEN_ADA_COMMON,
    label: 'Wheelchair-accessible building entrance and common area',
    service_id: 712,
  },
  {
    id: AccessibilityConstants.AMEN_ADA_ROOM,
    label: 'At least one wheelchair-accessible bedroom',
    service_id: 711,
  },
  {
    id: AccessibilityConstants.AMEN_ADA_BATH,
    label: 'At least one wheelchair-accessible bathroom',
  },
];

// used to prepend http:// to the url if it is not already there

export const withHttp = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }

  return url;
};

export default withHttp;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { handleFontsize } from './Text';
import colors from '../../scss/color.module.scss';

const RHLink = styled(Link)`
  color: ${colors.textlink};
  font-size: ${(props) => handleFontsize(props)};
  text-decoration: underline;
  font-weight: 400;
  line-height: ${(props) => props.lineHeight || '19px'};
`;

export { RHLink };

export const HouseStatusConstants = {
  UNKNOWN: 0,
  AVAILABLE: 1,
  UNAVAILABLE: 2,
  CALL_FOR_AVAILABILITY: 3,
};

export const HouseStatusData = [
  {
    id: HouseStatusConstants.UNKNOWN,
    label: 'Unknown',
  },
  {
    id: HouseStatusConstants.AVAILABLE,
    label: 'Available beds',
  },
  {
    id: HouseStatusConstants.UNAVAILABLE,
    label: 'No beds available',
  },
  {
    id: HouseStatusConstants.CALL_FOR_AVAILABILITY,
    label: 'Contact for availability',
  },
];

import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './features/searchSlice';
import filterReducer from './features/getHelpDataSlice';
import guidedSearchReducer from './features/guidedSearchSlice';

export default configureStore({
  reducer: {
    search: searchReducer,
    filter: filterReducer,
    guidedSearch: guidedSearchReducer,
  },
});

export const SmokingConstants = {
  PT_TOBACCO_ALLOWED_DESIGNATED: 'PT_TOBACCO_ALLOWED_DESIGNATED',
  PT_TOBACCO_ALLOWED: 'PT_TOBACCO_ALLOWED',
  PT_TOBACCO_NOT_ALLOWED: 'PT_TOBACCO_NOT_ALLOWED',
};

export const SmokingData = [
  {
    id: SmokingConstants.PT_TOBACCO_ALLOWED,
    label: 'Tobacco Allowed',
    icon: 'check',
  },
  {
    id: SmokingConstants.PT_TOBACCO_NOT_ALLOWED,
    label: 'Tobacco Allowed',
    icon: 'times',
  },
  {
    id: SmokingConstants.PT_TOBACCO_ALLOWED_DESIGNATED,
    label: 'Tobacco use allowed in designated areas only',
    icon: 'check',
  },
];

export const GuidedSearchSmokingOptions = [
  {
    id: 'Smoking_tobacco',
    label: 'Smoking tobacco (e.g. cigarettes, cigars, pipes, etc.)',
  },
  {
    id: 'Smokeless_tobacco',
    label: 'Smokeless tobacco (e.g. dip, chew, etc.)',
  },
  {
    id: 'Tobacco-free_smoking',
    label: 'Tobacco-free smoking (e.g. vaping)',
  },
  {
    id: 'Tobacco_and_smoke-free',
    label: 'Tobacco and smoke-free (e.g. gum, patches. etc.)',
  },
];

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Text } from '../../theme';
import colors from '../../scss/color.module.scss';
const { primary } = colors;
import classnames from 'classnames';

const ModalToggle = styled.button.attrs({
  className: 'primary text-center',
})`
  border: none;
  background: transparent;
  color: ${primary};
  width: fit-content;
  margin: 0 auto;
`;

const BasicModal = ({
  modalSize = 'xl',
  toggleText = 'Open',
  toggleIcon = ['fas', 'question'],
  title = '',
  children,
  id,
  label,
  usePadding = true,
  useCustomButton = false,
  customButton,
}) => {
  return (
    <React.Fragment>
      {useCustomButton ? (
        React.cloneElement(customButton, { 'data-bs-target': `#${id}`, 'data-bs-toggle': 'modal' })
      ) : (
        <ModalToggle data-bs-target={`#${id}`} data-bs-toggle="modal">
          {toggleText} &nbsp;
          <FontAwesomeIcon icon={[toggleIcon[0], toggleIcon[1]]} />
        </ModalToggle>
      )}
      <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={label} aria-hidden="true">
        <div className={`modal-dialog modal-${modalSize}`}>
          <div
            className="modal-content"
            style={usePadding ? { padding: '20px 40px' } : { padding: '0px' }}
          >
            <div className="modal-header border-0">
              <Text extra_large bold className="w-100 text-center" id={label}>
                {title}
              </Text>
              <button
                type="button"
                className="btn btn-close closeButtonLink"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className={classnames('modal-body', { 'p-0': !usePadding })}>{children}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasicModal;

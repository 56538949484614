export const CriminalBackgroundConstants = {
  ARSON: 'ARSON',
  ASSAULT: 'ASSAULT',
  BATTERY: 'BATTERY',
  FELONY_STATUS: 'FELONY_STATUS',
  HOME_INCARCERATION: 'HOME_INCARCERATION',
  SEX_OFFENDER: 'SEX_OFFENDER',
  VIOLENT_OFFENDER: 'VIOLENT_OFFENDER',
  NON_APPLICABLE: 'NON_APPLICABLE',
};

export const CriminalBackgroundData = [
  {
    id: CriminalBackgroundConstants.ARSON,
    label: 'Arson',
  },
  {
    id: CriminalBackgroundConstants.ASSAULT,
    label: 'Assault',
  },
  {
    id: CriminalBackgroundConstants.BATTERY,
    label: 'Battery',
  },
  {
    id: CriminalBackgroundConstants.FELONY_STATUS,
    label: 'Felony status',
  },
  {
    id: CriminalBackgroundConstants.HOME_INCARCERATION,
    label: 'Home incarceration',
  },
  {
    id: CriminalBackgroundConstants.SEX_OFFENDER,
    label: 'Sex offender',
  },
  {
    id: CriminalBackgroundConstants.VIOLENT_OFFENDER,
    label: 'Violent offender',
  },
  {
    id: CriminalBackgroundConstants.NON_APPLICABLE,
    label: 'Non-applicable',
  },
];

export const AdditionalWalkableServicesConstants = {
  CONVENIENCE_STORE: 'CONVENIENCE_STORE',
  DENTAL_SERVICES: 'DENTAL_SERVICES',
  GROCERY_STORE: 'GROCERY_STORE',
  GAS_STATION: 'GAS_STATION',
  HEALTHCARE: 'HEALTHCARE',
  PHARMACY: 'PHARMACY',
  RESTAURANTS: 'RESTAURANTS',
  OTHER: 'OTHER',
};

export const AdditionalWalkableServicesData = [
  {
    id: AdditionalWalkableServicesConstants.CONVENIENCE_STORE,
    label: 'Convenience Store',
  },
  {
    id: AdditionalWalkableServicesConstants.DENTAL_SERVICES,
    label: 'Dental services',
  },
  {
    id: AdditionalWalkableServicesConstants.GROCERY_STORE,
    label: 'Grocery store',
  },
  {
    id: AdditionalWalkableServicesConstants.GAS_STATION,
    label: 'Gas station',
  },
  {
    id: AdditionalWalkableServicesConstants.HEALTHCARE,
    label: 'Healthcare',
  },
  {
    id: AdditionalWalkableServicesConstants.PHARMACY,
    label: 'Pharmacy',
  },
  {
    id: AdditionalWalkableServicesConstants.RESTAURANTS,
    label: 'Restaurants',
  },
  {
    id: AdditionalWalkableServicesConstants.OTHER,
    label: 'Other',
  },
];

import React from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import './SaveSearchPopover.scss';

export const SaveSearchPopover = ({ isPopoverOpen, setIsPopoverOpen, children, content }) => {
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'left']} // priority list of preferred positions for popover in relation to its target
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      reposition={true} // popover content will reposition on a boundary collision
      boundaryInset={10} // inset around boundary that boundary violations are determined at
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="popover-content-container">{content}</div>
        </ArrowContainer>
      )}
    >
      {children}
    </Popover>
  );
};

export const MedTreatmentConstants = {
  MED_TREATMENT_MOUD: 'MED_TREATMENT_MOUD',
  SELF: 'SELF',
  PRESCRIBED: 'PRESCRIBED',
  MANAGED: 'MANAGED',
  NONE: 'NONE',
};

export const MedTreatmentLabel = {
  label: 'Medication for Opioid Use Disorder (MOUD) friendly',
};

export const MedTreatmentData = [
  {
    id: MedTreatmentConstants.MED_TREATMENT_MOUD,
    label: 'Medication for Opioid Use Disorder (MOUD) friendly',
    icon: 'times',
    service_id: 144,
  },
  {
    id: MedTreatmentConstants.SELF,
    label: 'Medication for Opioid Use Disorder (MOUD) friendly',
    icon: 'check',
  },
  {
    id: MedTreatmentConstants.PRESCRIBED,
    label: 'Medication for Opioid Use Disorder (MOUD) friendly',
    icon: 'check',
  },
  {
    id: MedTreatmentConstants.MANAGED,
    label: 'Medication for Opioid Use Disorder (MOUD) friendly',
    icon: 'check',
  },
  {
    id: MedTreatmentConstants.NONE,
    label: 'Medication for Opioid Use Disorder (MOUD) friendly',
    icon: 'times',
  },
];

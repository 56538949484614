import {
  ResidentsServedConstants,
  ResidentsServedData,
} from '../AppData/GetHelpConstants/ResidentsServed';
import { cloneDeep } from 'lodash';

export const createOptions = (type) => {
  let list;
  let data;
  switch (type) {
    case 'gender':
      list = [
        ResidentsServedConstants.POP_MALE_ONLY,
        ResidentsServedConstants.POP_FEMALE_ONLY,
        ResidentsServedConstants.POP_CHILDREN_MEN,
        ResidentsServedConstants.POP_CHILDREN_WOMEN,
        ResidentsServedConstants.POP_COUPLE,
        ResidentsServedConstants.POP_TRANS_WOMEN,
        ResidentsServedConstants.POP_TRANS_MAN,
        ResidentsServedConstants.POP_INTERSEX,
        ResidentsServedConstants.POP_NON_BINARY,
        ResidentsServedConstants.POP_COED,
      ];
      data = cloneDeep(ResidentsServedData);
      break;
    case 'characteristics':
      list = [
        ResidentsServedConstants.POP_BLIND,
        ResidentsServedConstants.POP_DEAF,
        ResidentsServedConstants.POP_HOMELESS,
        ResidentsServedConstants.POP_INDIGENOUS,
        ResidentsServedConstants.POP_RENTRY,
        ResidentsServedConstants.POP_VETERANS,
        ResidentsServedConstants.POP_JUSTICE,
        ResidentsServedConstants.POP_PREGNANCY,
        ResidentsServedConstants.POP_LGBTQ,
        ResidentsServedConstants.CO_OCCURRING_MENTAL_HEALTH_DISORDER,
        ResidentsServedConstants.POP_CHRONIC_CONDITION,
        // commented out for alpha release
        // ResidentsServedConstants.POP_UNDER_18,
      ];
      data = cloneDeep(ResidentsServedData);
      break;
  }

  let options = [];

  data.forEach((resident) => {
    if (list.includes(resident.id)) {
      delete resident.icon;
      options.push(resident);
    }
  });

  return options;
};

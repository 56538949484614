import styled from 'styled-components';
import colors from '../../scss/color.module.scss';

const HR = styled.hr`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid ${colors.inputlines};
`;

export { HR };

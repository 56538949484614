export const MedicationsConstants = {
  BUPRENORPHINE: 'BUPRENORPHINE',
  SUBUTEX: 'SUBUTEX',
  SUBOXONE: 'SUBOXONE',
  NALTREXONE: 'NALTREXONE',
  VIVITROL: 'VIVITROL',
  METHADONE: 'METHADONE',
};

export const MedicationsData = [
  {
    id: MedicationsConstants.BUPRENORPHINE,
    label: 'Buprenorphine',
  },
  {
    id: MedicationsConstants.SUBUTEX,
    label: 'Subutex',
  },
  {
    id: MedicationsConstants.SUBOXONE,
    label: 'Suboxone',
  },
  {
    id: MedicationsConstants.NALTREXONE,
    label: 'Naltrexone',
  },
  {
    id: MedicationsConstants.VIVITROL,
    label: 'Vivitrol',
  },
  {
    id: MedicationsConstants.METHADONE,
    label: 'Methadone',
  },
];

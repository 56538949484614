export const SearchDistanceConstants = {
  MILES_10: 10,
  MILES_25: 25,
  MILES_50: 50,
  MILES_100: 100,
  MILES_150: 150,
};

export const SearchDistanceData = [
  {
    id: SearchDistanceConstants.MILES_10,
    label: 'within 10 miles',
  },
  {
    id: SearchDistanceConstants.MILES_25,
    label: 'within 25 miles',
  },
  {
    id: SearchDistanceConstants.MILES_50,
    label: 'within 50 miles',
  },
  {
    id: SearchDistanceConstants.MILES_100,
    label: 'within 100 miles',
  },
  {
    id: SearchDistanceConstants.MILES_150,
    label: 'within 150+ miles',
  },
];

import { useMediaQuery } from 'react-responsive';

import layout from '../../scss/layout.module.scss';

export const useMobile = () => {
  const isMobile = useMediaQuery({ maxWidth: layout.mobileMaxWidth });
  const isTablet = useMediaQuery({ maxWidth: layout.tabletMaxWidth });
  const isLargeScreen = useMediaQuery({ maxWidth: layout.largeMaxWidth });
  const isXLScreen = useMediaQuery({ minWidth: layout.XLMinWidth });

  return {
    isMobile,
    isTablet,
    isLargeScreen,
    isXLScreen,
  };
};

export const PolicyDocTagsConstants = {
  DISCHARGE_PROCEDURES: 'DISCHARGE_PROCEDURES',
  EDUCATION: 'EDUCATION',
  FINANCIAL: 'FINANCIAL',
  REFUND_BELONGINGS: 'REFUND_BELONGINGS',
  RELAPSE_OR_RETURN: 'RELAPSE_OR_RETURN',
  RESIDENT_BEHAVIOUR: 'RESIDENT_BEHAVIOUR',
  WORK_REQUIREMENTS: 'WORK_REQUIREMENTS',
};

export const PolicyDocTagsData = [
  {
    id: PolicyDocTagsConstants.DISCHARGE_PROCEDURES,
    label: 'Discharge',
  },
  {
    id: PolicyDocTagsConstants.EDUCATION,
    label: 'Education',
  },
  {
    id: PolicyDocTagsConstants.FINANCIAL,
    label: 'Financial',
  },
  {
    id: PolicyDocTagsConstants.REFUND_BELONGINGS,
    label: 'Refund',
  },
  {
    id: PolicyDocTagsConstants.RELAPSE_OR_RETURN,
    label: 'Relapse/return to use policies',
  },
  {
    id: PolicyDocTagsConstants.RESIDENT_BEHAVIOUR,
    label: 'Resident behavior',
  },
  {
    id: PolicyDocTagsConstants.WORK_REQUIREMENTS,
    label: 'Work requirements',
  },
];

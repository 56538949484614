export const AccreditationsConstants = {
  NARR: 'NARR',
  OXFORD_HOUSE: 'Oxford House',
};

export const AccreditationsData = [
  {
    id: AccreditationsConstants.NARR,
    label: 'NARR',
    accreditation: 34,
    association: 1,
  },
  {
    id: AccreditationsConstants.OXFORD_HOUSE,
    label: 'Oxford House',
    association: 4,
  },
];

import { createSlice } from '@reduxjs/toolkit';

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    params: '',
    refresh: null,
  },
  reducers: {
    setGetHelpData: (state, action) => {
      state.params = action.payload.params;
      state.timestamp = action.payload.timestamp;
    },
  },
});

export const { setGetHelpData } = filterSlice.actions;

export default filterSlice.reducer;
